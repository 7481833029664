<template>
  <div class="order-details">
    <ul class="details-container">
      <li class="date">
        <p class="h6">
          <strong>{{ $t("sidebar.desiredDeliveryDate") }}:</strong>
        </p>
        <p class="h6 value">
          {{ date || "-" }}
        </p>
      </li>
      <li class="shipment">
        <p class="h6">
          <strong>{{ $t("sidebar.shipment") }}:</strong>
        </p>
        <div
          v-if="
            orderDetails.address ||
            orderDetails.postal_code ||
            orderDetails.locality
          "
        >
          <p class="h6 value address">{{ orderDetails.address }},</p>
          <p class="h6 value postal-code">
            {{ orderDetails.postal_code }}, {{ orderDetails.locality }}
          </p>
        </div>
        <div v-else>-</div>
      </li>
      <li class="installations">
        <p class="h6">
          <strong>{{ $t("sidebar.associatedPlants") }}:</strong>
        </p>
        <p class="h6 value">-</p>
      </li>
      <li class="subtotal-container">
        <p class="h4">
          <strong>{{ $t("sidebar.subtotal") }}:</strong>
        </p>
        <p class="h4 value subtotal">
          <strong> € {{ total }} </strong>
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["orderDetails"]),

    total() {
      let total = 0;
      if (this.orderDetails && this.orderDetails.total) {
        total = this.orderDetails.total;
        total = Math.round(total * 100) / 100;
      }
      return total;
    },

    date() {
      const date = new Date(Number(this.orderDetails.order_date + 100));
      let day = date.getDate();
      let month = date.getMonth() + 1;
      const year = date.getFullYear();

      day = day <= 9 ? "0" + day : day;
      month = month <= 9 ? "0" + month : month;

      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.order-details {
  padding: 2rem 0;

  .details-container {
    list-style: none;
    padding: 0;

    p {
      font-weight: 400;
      margin: 1.5rem 0 0.5rem;
    }

    .value {
      margin: 0 0 0.3rem 0;
    }
  }
}
</style>