<template>
  <aside class="control-sidebar sidebar-orders">
    <div class="sidebar-container">
      <button data-toggle="control-sidebar" class="cross hidden-md hidden-lg">
        <i class="fa fa-close"></i>
      </button>
      <AlertError />
      <transition name="loading-anim" mode="out-in">
        <div v-if="loading" key="loading">
          <Loading />
        </div>
        <div v-else class="content-container" key="loaded">
          <ActiveOrderId />
          <transition name="active-order" mode="out-in">
            <ActiveOrder v-if="isActiveOrder && !changeOrderOpen" />
            <SelectActivableOrder v-else />
          </transition>
        </div>
      </transition>
    </div>
  </aside>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Loading from "./Loading.vue";
import ActiveOrderId from "./ActiveOrderId.vue";
import ActiveOrder from "./ActiveOrder.vue";
import SelectActivableOrder from "./SelectActivableOrder.vue";
import AlertError from "./AlertError.vue";
// import ReconnectingWebSocket from 'reconnecting-websocket'

export default {
  components: {
    Loading,
    ActiveOrderId,
    ActiveOrder,
    SelectActivableOrder,
    AlertError,
  },

  methods: {
    // Product notifications
    showAddedProduct(productCode, productName) {
      this.$swal({
        title: this.$t("notification.productAdded"),
        html: this.$t("notification.productIdName", {
          productCode: productCode,
          productName: productName,
        }),
        icon: "success",
        toast: true,
        position: "top-end",
        timer: "5000",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    },

    showRemovedProduct(productCode, productName) {
      this.$swal({
        title: this.$tc("notification.productRemoved"),
        html: this.$t("notification.productIdName", {
          productCode: productCode,
          productName: productName,
        }),
        icon: "warning",
        toast: true,
        position: "top-end",
        timer: "3000",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    },

    showUpdatedProduct(productCode, productName) {
      this.$swal({
        title: this.$tc("notification.productUpdated"),
        html: this.$t("notification.productIdName", {
          productCode: productCode,
          productName: productName,
        }),
        icon: "info",
        toast: true,
        position: "top-end",
        timer: "3000",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    },

    // Order notifications
    showUpdatedOrder(orderId, orderName) {
      this.$swal({
        title: this.$tc("notification.orderUpdated"),
        html: this.$t("notification.orderIdNameUpdated", {
          orderId: orderId,
          orderName: orderName,
        }),
        icon: "info",
        toast: true,
        position: "top-end",
        timer: "3000",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    },

    showActiveOrderChange(orderId, orderName) {
      this.$swal({
        title: this.$tc("notification.activeOrderChanged"),
        html: this.$t("notification.orderIdNameActivated", {
          orderId: orderId,
          orderName: orderName,
        }),
        icon: "success",
        toast: true,
        position: "top-end",
        timer: "3000",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    },

    showDeletedOrder(orderId, orderName) {
      this.$swal({
        title: this.$tc("notification.orderDeleted"),
        html: this.$t("notification.orderIdNameDeleted", {
          orderId: orderId,
          orderName: orderName,
        }),
        icon: "warning",
        toast: true,
        position: "top-end",
        timer: "3000",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    },

    showClosedOrder(orderId, orderName) {
      this.$swal({
        title: this.$tc("notification.orderClosed"),
        html: this.$t("notification.orderIdNameCloseed", {
          orderId: orderId,
          orderName: orderName,
        }),
        icon: "warning",
        toast: true,
        position: "top-end",
        timer: "3000",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    },
  },

  computed: {
    ...mapState(["changeOrderOpen", "loading", "orderDetails", "config"]),
    ...mapGetters(["isActiveOrder"]),
  },

  mounted() {
    this.$store.dispatch("fetchActiveOrder");
  },

  watch: {
    isActiveOrder: {
      handler() {
        this.$store.commit("toggleLoading", false);
      },
    },
  },

  created() {
    // WEBSOCKET MANAGEMENT
    const socket = new WebSocket(this.config.wsUri);

    socket.onmessage = (message) => {
      const ws = JSON.parse(message.data);
      if (ws.appid == this.config.wsAppId) {
        console.log("ws", ws);

        const wsData = ws.extras;
        let updatedObject = JSON.parse(wsData.objectUpdated);

        if (wsData) {
          switch (wsData.messageType) {
            //  Order
            case "orderActivated": {
              this.$store.dispatch("fetchActiveOrder");
              this.showActiveOrderChange(updatedObject.id, updatedObject.name);
              break;
            }

            case "orderUpdated": {
              this.$store.dispatch("fetchActiveOrder");
              this.showUpdatedOrder(updatedObject.id, updatedObject.name);
              break;
            }

            case "orderDeleted": {
              this.$store.commit("toggleSelectActivableOrder", true);
              this.$store.dispatch("fetchActivableOrders");
              this.$store.dispatch("fetchActiveOrder");
              this.showDeletedOrder(updatedObject.id, updatedObject.name);
              break;
            }

            case "orderClosed": {
              this.$store.commit("toggleSelectActivableOrder", true);
              this.$store.dispatch("fetchActivableOrders");
              this.showClosedOrder(updatedObject.id, updatedObject.name);
              break;
            }

            // OrderItem
            case "orderItemCreated": {
              this.$store.dispatch("fetchActiveOrder");
              this.showAddedProduct(
                updatedObject.article_cod,
                updatedObject.name
              );
              break;
            }

            case "orderItemUpdated": {
              this.$store.dispatch("fetchActiveOrder");
              this.showUpdatedProduct(updatedObject.article_cod);
              break;
            }

            case "orderItemDeleted": {
              this.$store.dispatch("fetchActiveOrder");
              let productName = updatedObject.article.article_description;
              this.showRemovedProduct(
                updatedObject.article.article_cod,
                productName.name || null
              );
              break;
            }
          }
        }
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.sidebar-container {
  padding-bottom: 0;
  position: relative;
  height: calc(100vh - 40px);

  .cross {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    font-size: 2rem;
    background: none;
    border: none;
  }

  .content-container {
    min-height: 100%;
    height: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 0;

    .face {
      font-family: initial;
    }
  }
}
</style>

<style scoped>
.active-order-enter-active {
  transition: all 0.2s ease;
}
.active-order-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.active-order-enter,
.active-order-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.loading-anim-enter-active,
.loading-anim-leave-active {
  transition: opacity 0.2s ease;
}
.loading-anim-enter,
.loading-anim-leave-to {
  opacity: 0;
}
</style>

<style>
.sidebar-orders {
  width: 230px;
  right: -230px;
  max-width: 100vw;
}
.control-sidebar {
  padding: 5rem 0 0 0 !important;
  overflow-y: scroll;
  max-height: 100vh;
  border: none;
  box-shadow: 1px 0px 5px rgb(0 0 0 / 10%);
}
.control-sidebar::-webkit-scrollbar {
  display: none;
}
</style>