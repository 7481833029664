/* global $ */

module.exports = (function () {
    //  Display control-sidebar always on desktop, toggle only on mobile
    function manageSidebar() {
        if (window.matchMedia('(min-width: 1024px)').matches) {
            $(".load-more").controlSidebar({ controlsidebarSlide: false });

            if (!$('.control-sidebar').hasClass('control-sidebar-open')) {
                $(".load-more").controlSidebar('toggle');
            }
        } else {
            console.log($(".load-more"))
            $(".load-more").controlSidebar({ controlsidebarSlide: true });
        }
    }

    $(window).on('load', function () {
        manageSidebar()
    })
})()