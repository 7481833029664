<template>
  <div>
    <ActivableOrdersList />
    <div class="divider">
      <span class="line"></span>
      <span>{{ $t("select.or") }}</span>
      <span class="line"></span>
    </div>
    <a
      :href="`${config.adminOrderCreate}`"
      class="btn btn-block"
    >
      {{ $t("select.createNewOrder") }}
    </a>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ActivableOrdersList from "./ActivableOrdersList.vue";
export default {
  components: {
    ActivableOrdersList,
  },

  computed: {
    ...mapState(["config"]),
  },

  mounted() {
    this.$store.dispatch("fetchActivableOrders");
  },
};
</script>

<style lang="scss" scoped>
.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: #4e4e4e;
  margin: 1.5rem 0;

  .line {
    width: 100%;
    height: 2px;
    background: #4e4e4e;
  }
}

a {
  background: rgba(51, 51, 51, 1);
  color: white !important;
  transition: background-color .2s;

  &:hover {
    background: rgba(51, 51, 51, 0.9);
  }
}
</style>

