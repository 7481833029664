<template>
  <div>
    <div v-if="article" class="product-container">
      <div class="product">
        <p class="h6 quantity badge">{{ quantity || 0 }}</p>

        <figure class="icon">
          <img
            :src="article.cover_image.image"
            alt=""
            v-if="article.cover_image"
          />
        </figure>
        <div class="product-info">
          <p class="h6 name">{{ article.article_description.name }}</p>
          <p class="h6 dimension" v-if="dimension">
            {{ dimension }} <span>{{ article.unit_of_measure_cod }}</span>
          </p>
          <p class="h6 text-uppercase product-id">
            {{ article.article_cod }}
          </p>
        </div>
      </div>

      <button
        class="btn btn-danger btn-sm remove-item"
        @click="showDeleteAlert"
      >
        <i class="remove fa fa-trash-o"></i>
      </button>
    </div>
    <AlertDelete
      v-if="article"
      :id="id"
      :articleName="article.article_description.name"
      :articleCode="article.article_cod"
      :articleQuantity="quantity"
      :dimension="dimension"
      :unitOfMeasure="article.unit_of_measure_cod"
    />
  </div>
</template>

<script>
import AlertDelete from "./AlertDelete.vue";

export default {
  components: {
    AlertDelete,
  },

  props: {
    quantity: Number,
    article: Object,
    id: Number,
    dimension: Number,
  },

  methods: {
    showDeleteAlert() {
      /* global $ */
      $(`.modal-delete[data-id=${this.id}]`).modal("show");
    },
  },
};
</script>

<style lang="scss" scoped>
.product-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #ddd;
  padding-bottom: 0.2rem;
  margin: 0;
}

.product {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  max-width: 80%;

  .quantity {
    font-size: 1rem;
    white-space: nowrap;
    min-width: 20px;
    background: #ffcd1c;
    color: #333;
  }

  .icon {
    flex-shrink: 0;
    height: 2.5rem;
    width: 2.5rem;
    background: #eee;
    flex-shrink: 0;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .product-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // max-height: 2.5rem;
    margin: 0;
    max-width: 70%;

    .product-id {
      font-size: 0.8rem;
      font-weight: 700;
      color: #999;
      margin: 0;
      letter-spacing: 0.1em;
    }
    .name {
      font-weight: 400;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .dimension {
      margin: 0.1rem 0;
      font-weight: 400;
      font-size: 1rem;

      span {
        margin-left: 0.1rem;
      }
    }
  }
}

.remove-item:focus {
  outline: none;
}
</style>