import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import { store } from './store/index'
import Sidebar from './components/ControlSidebar.vue'
import Footer from './components/Footer.vue'
import i18n from './i18n'
import './globals'

import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false

Vue.use(VueSweetalert2)

new Vue({
  store,
  i18n,
  render: h => h(Sidebar)
}).$mount('#sidebar')

new Vue({
  store,
  i18n,
  render: h => h(Footer)
}).$mount('#footer')