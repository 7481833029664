<template>
  <div>
    <div class="modal modal-default fade modal-delete" :data-id="id">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button class="close" data-dismiss="modal">
              <span aria-hidden="true">x</span>
            </button>
            <p class="h4 modal-title">{{ $t("alert.warning.title") }}</p>
          </div>
          <div class="modal-body">
            <p class="h5">{{ $t("alert.delete.removeArticleText") }}</p>
            {{ $t("alert.delete.articleName") }}: {{ articleName }} <br />
            {{ $t("alert.delete.articleCode") }}: {{ articleCode }} <br />
            {{ $t("alert.delete.articleQuantity") }}: {{ articleQuantity }}
            <span v-if="dimension">
              <br />
              {{ $t("alert.delete.articleDimension") }}: {{ dimension }}
              {{ unitOfMeasure }}
            </span>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn pull-left" data-dismiss="modal">
              {{ $t("alert.warning.back") }}
            </button>
            <button
              type="button"
              class="btn btn-danger button-remove"
              @click="removeProduct"
              :disabled="loading"
            >
              {{ $t("alert.delete.removeArticle") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    articleName: String,
    articleCode: String,
    articleQuantity: Number,
    dimension: Number,
    unitOfMeasure: String,
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    removeProduct() {
      this.loading = true;
      this.$store.dispatch("removeProduct", this.id).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
.face {
  padding-left: 0.5rem;
  /* font-family: monospace; */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
</style>

<style>
.modal {
  padding-top: 20vh;
}
.modal-backdrop {
  display: none;
}

.button-remove:disabled {
  cursor: wait;
}
</style>