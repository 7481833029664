<template>
  <div class="products-list">
    <p class="h5">
      {{ $t("sidebar.lastAddedProducts") }}
      <span class="badge">{{ productsQuantity }} </span>
    </p>
    <transition name="no-products-anim" mode="out-in">
      <div
        class="products-container"
        v-if="orderDetails.items.length"
        key="not-empty"
      >
        <div class="fade-border-top"></div>
        <transition-group tag="ul" class="list" name="products">
          <li v-for="product in products" :key="product.id" class="product">
            <Product
              :quantity="product.quantity"
              :article="product.article"
              :id="product.id"
              :dimension="product.dimension"
            />
          </li>
        </transition-group>
        <div class="fade-border"></div>
      </div>
      <div class="no-product" v-else key="empty">
        <i class="fa fa-archive"></i>
        <p class="h6 text-gray">Nessun prodotto aggiunto</p>
      </div>
    </transition>
  </div>
</template>

<script>
import Product from "./Product.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      showLess: true,
    };
  },

  components: {
    Product,
  },

  computed: {
    ...mapState(["orderDetails"]),

    productsQuantity() {
      let quantity = 0;
      this.orderDetails.items.forEach((el) => {
        quantity += el.quantity;
      });
      return quantity;
    },

    products() {
      let products = this.orderDetails.items;
      products = products.sort((a, b) => {
        if (a.id < b.id) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        }
        return 0;
      });

      return products;
    },
  },
};
</script>

<style lang="scss" scoped>
.products-list {

  .badge {
    background: #ffcd1c;
    color: #333;
  }

  .products-container {
    position: relative;

    .list {
      list-style: none;
      padding: 0;
      max-height: 40vh;
      overflow: scroll;
      position: relative;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .fade-border {
      position: absolute;
      pointer-events: none;
      width: 100%;
      height: 2rem;
      bottom: -1px;
      left: 0;
      background: linear-gradient(
        to bottom,
        rgba(251, 251, 251, 0) 50%,
        rgb(251, 251, 251, 1) 100%
      );
    }

    .fade-border-top {
      position: absolute;
      pointer-events: none;
      z-index: 1;
      width: 100%;
      height: 1rem;
      top: -1px;
      left: 0;
      background: linear-gradient(
        to bottom,
        rgb(251, 251, 251, 1) 0%,
        rgba(251, 251, 251, 0) 50%
      );
    }
  }

  .no-product {
    display: flex;
    align-items: center;

    i {
      font-size: 2.5rem;
      color: #d2d6de;
      margin-right: 1rem;
    }

    p {
      flex-wrap: wrap;
      max-width: 70%;
    }
  }
}

/* ANIMATIONS */
.product {
  transition: all 0.5s;
  display: inline-block;
  width: 100%;
}
.products-enter,
.products-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.products-leave-active {
  position: absolute;
  left: 0;
}

.no-products-anim-enter-active,
.no-products-anim-leave-active {
  transition: opacity 0.3s ease;
}

.no-products-anim-enter,
.no-products-anim-leave-to {
  opacity: 0;
}
</style>