<template>
  <div class="sidebar-footer-btns" v-if="orderDetails.link">
    <a
      v-if="orderDetails.link[1].name == 'view'"
      :href="orderDetails.link[1].url"
      class="btn btn-app btn-view"
    >
      <i class="fa fa-th-list"></i>
      {{ $t("sidebar.see") }}
    </a>
    <a
      v-if="orderDetails.link[0].name == 'edit'"
      :href="orderDetails.link[0].url"
      class="btn btn-app btn-edit"
    >
      <i class="fa fa-pencil"></i>
      {{ $t("sidebar.modify") }}
    </a>
    <a
      v-if="orderDetails.link[2].name == 'confirm'"
      :href="orderDetails.link[2].url"
      class="btn btn-success btn-app"
    >
      <i class="fa fa-check"></i>
      {{ $t("sidebar.confirm") }}
    </a>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["orderDetails"]),
  },
};
</script>

<style lang="scss" scoped>
.sidebar-footer-btns {
  position: sticky;
  z-index: 2;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
  padding: 1rem 0;
  margin-top: 2rem;
  background: white;

  @media screen and(min-width: 1440px) {
    margin-top: 0;
  }

  .btn {
    font-size: 1rem;
    padding: 0.5rem;
    margin: 0;
    height: auto;
    min-width: auto;
    width: 33%;

    i {
      font-size: 1.5rem;
    }

    &.btn-view {
      background: rgba(51, 51, 51, 1);
      color: white;
      transition: background-color 0.2s;

      &:hover {
        background: rgba(51, 51, 51, 0.9);
      }
    }

    &.btn-edit {
      background: #ffcd1c;
      color: #333;
      transition: background-color 0.2s;

      &:hover {
        background: #ecbe1a;
      }
    }
  }
}
</style>