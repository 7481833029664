<template>
  <div class="active-order-container">
    <p
      class="h5 active-order-header text-muted"
      :class="{ 'label-footer': inFooter }"
    >
      {{ $t("order.activeOrder") }}
      <span v-if="orderDetails.id">n° {{ orderDetails.id }}</span>
    </p>
    <div v-if="orderDetails && orderDetails.name">
      <p class="h3 active-order" :class="inFooter && 'footer-title'">
        {{ orderDetails.name }}
      </p>
      <button
        v-if="!inFooter"
        class="change-active-order-btn btn"
        @click="toggleSelectActivableOrder"
      >
        <span v-if="!changeOrderOpen">
          {{ $t("order.changeOrder") }}
          <i class="fa fa-refresh"></i>
        </span>
        <span v-else
          >{{ $t("order.backToOrder") }} <i class="fa fa-arrow-right"></i>
        </span>
      </button>
    </div>
    <div v-else>
      <p
        class="h3 active-order"
        :class="{ 'footer-no-active-order': inFooter }"
      >
        {{ $t("order.noOrder") }}
      </p>
      <p class="info" v-if="!inFooter">
        {{ $t("order.orderInstructions") }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    inFooter: Boolean,
  },

  methods: {
    toggleSelectActivableOrder() {
      this.$store.commit("toggleSelectActivableOrder", !this.changeOrderOpen);
    },
  },

  computed: {
    ...mapState(["orderDetails", "changeOrderOpen"]),
  },
};
</script>

<style lang="scss" scoped>
.active-order-container {
  .active-order {
    max-height: 5.2rem;
    line-height: 2.5rem;
    overflow: auto;
    margin-top: 0.5rem;

    &::-webkit-scrollbar {
      display: none;
    }

    &.footer-no-active-order,
    &.footer-title {
      color: white;
    }
  }

  button {
    background: rgba(51, 51, 51, 1);
    color: white;
    transition: background-color 0.2s;

    &:hover {
      background: rgba(51, 51, 51, 0.9);
    }
  }

  .label-footer {
    color: #ffcd1c;
  }

  .change-active-order-btn {
    outline: none;

    i {
      margin-left: 0.5rem;
    }

    &:hover .fa-refresh {
      transition: transform 0.3s ease;
      transform: rotate(180deg);
    }
  }

  .info {
    font-size: 1.2rem;
    color: #212121;
  }
}
</style>