<template>
  <div class="sidebar">
    <div>
      <OrderDetails />
      <ProductsList />
    </div>
    <SidebarFooter />
  </div>
</template>

<script>
import OrderDetails from "./OrderDetails.vue";
import ProductsList from "./ProductsList.vue";
import SidebarFooter from "./SidebarFooter.vue";
export default {
  components: {
    OrderDetails,
    ProductsList,
    SidebarFooter,
  },
};
</script>

<style scoped>
.sidebar {
  height: 85%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
