<template>
  <div>
    <div class="modal modal-default fade modal-id">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button class="close" data-dismiss="modal">
              <span aria-hidden="true">x</span>
            </button>
            <p class="h4 modal-title">{{ $t("alert.warning.title") }}</p>
          </div>
          <div class="modal-body">
            <i18n path="alert.warning.replaceActiveOrder" tag="p">
              <template v-slot:currentOrder>
                <strong>
                  n° {{ currentOrderId }} - {{ currentOrderName }}
                </strong>
              </template>
              <template v-slot:selectedOrder>
                <strong slot="selectedOrder">
                  n° {{ selectedOrderName }}
                </strong>
              </template>
            </i18n>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn pull-left" data-dismiss="modal">
              {{ $t("alert.warning.back") }}
            </button>
            <button type="button" class="btn btn-default" @click="activeOrder">
              {{ $t("alert.warning.confirmChangeActiveOrder") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentOrderId: Number,
    selectedOrderId: Number,
    currentOrderName: String,
    selectedOrderName: String,
  },

  methods: {
    activeOrder() {
      this.$store.dispatch("activeSelectedOrder", this.selectedOrderId);
    },
  },
};
</script>

<style>
.modal {
  padding-top: 20vh;
}
.modal-backdrop {
  display: none;
}
</style>