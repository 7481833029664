<template>
  <div class="order-list-container">
    <label>{{ $t("select.selectOrderLabel") }}</label>

    <v-select
      class="select-order"
      v-model="select"
      :options="activableOrders"
      :selectable="(option) => option.id !== orderDetails.id"
    />
    <button
      class="btn btn-block"
      @click="changeOrder"
      role="dialog"
      aria-labelledby="warningOrderChange"
      aria-hidden="true"
    >
      {{ $t("select.selectOrder") }}
    </button>
    <Alert
      :currentOrderId="orderDetails.id"
      :currentOrderName="orderDetails.name"
      :selectedOrderId="select.id"
      :selectedOrderName="select.label"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Alert from "./Alert.vue";

Vue.component("v-select", vSelect);

export default {
  components: {
    Alert,
  },

  data() {
    return {
      select: "",
    };
  },

  methods: {
    changeOrder() {
      if (this.select.id) {
        if (this.orderDetails.id) {
          if (this.orderDetails.id !== this.select.id) {
            /* global $ */
            $(".modal-id").modal("show");
          }
        } else {
          this.$store.dispatch("activeSelectedOrder", this.select.id);
        }
      }
    },
  },

  computed: {
    ...mapState(["orderDetails", "orderList"]),

    activableOrders() {
      let orders = [];
      this.orderList.forEach((e) => {
        orders.push({
          label: `${e.id} - ${e.name}`,
          id: e.id,
        });
      });
      return orders;
    },
  },
};
</script>

<style lang="scss" scoped>
.order-list-container {
  padding-top: 2rem;

  .select-order {
    margin: 0 0 1rem 0;
  }

  button {
    background: #ffcd1c;
    color: #333;
    transition: background-color 0.2s;

    &:hover {
      background: #ecbe1a;
    }
  }
}
</style>

<style lang="scss">
.vs__clear {
  display: none;
}
</style>
