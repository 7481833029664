<template>
  <footer class="main-footer navbar-fixed-bottom">
    <ActiveOrderId :inFooter="true" />

    <button
      class="btn load-more hidden-md hidden-lg"
      data-toggle="control-sidebar"
    >
      <p class="h6">
        <strong v-if="orderDetails.id">{{ $t("sidebar.seeDetails") }}</strong>
        <strong v-else>{{ $t("select.selectOrderLabel") }}</strong>
      </p>
    </button>
  </footer>
</template>

<script>
import ActiveOrderId from "./ActiveOrderId.vue";
import { mapState } from "vuex";
export default {
  components: {
    ActiveOrderId,
  },

  computed: {
    ...mapState(["orderDetails", "orderID"]),
  },
};
</script>

<style lang="scss" scoped>
.main-footer {
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  z-index: 1000;
  background: #333 !important;

  .load-more {
    margin: auto 0;
    background: #ffcd1c;
    color: #333;

    &:focus {
      outline: none;
    }
  }
}
</style>
