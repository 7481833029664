<template>
  <div>
    <div class="modal modal-danger fade modal-error">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button class="close" data-dismiss="modal">
              <span aria-hidden="true">x</span>
            </button>
            <p class="h4 modal-title">{{ $t("alert.error.title") }}</p>
          </div>
          <div class="modal-body">
            <div class="sad-face">
              <div v-if="error == 'getActiveOrderDetails'">(╥﹏╥)</div>
              <div v-else-if="error == 'changeActiveOrder'">(≖_≖ )</div>
              <div v-else-if="error == 'removeProduct'">(╯°□°）╯︵ ┻━┻</div>
              <div v-else>⁀⊙﹏☉⁀</div>
            </div>
            <p>{{ $t(`alert.error.${error}`) }}</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-danger" data-dismiss="modal">
              {{ $t("alert.error.confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["error"]),
  },
};
</script>

<style lang="scss" scoped>
.modal-danger {
  padding-top: 20vh;
}
.modal-backdrop {
  display: none;
}
.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .sad-face {
    font-size: 5rem;
    margin-bottom: 2rem;
    font-family: serif;
  }

  p {
    font-size: 1.5rem;
  }
}
</style>

